<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-autocomplete
        v-model="selectedTransformation"
        @input="handleInput"
        :items="items"
        :loading="isLoading"
        color="white"
        label="Transformation Type"
        placeholder="Choose a Transformation Type from dropdown"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="12" md="12">
      <v-text-field
        @change="handleTransformArgsInput"
        v-model="setTransformArgsTo"
        label="Transformation Arguments"
        persistent-hint
        :disabled="!selectedTransformation"
        :hint="'Parameters: ' + paramsDescription || '--'"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TransformationAutocomplete',
  props: {
    selectedTransformationField: {
      type: String,
      default: null,
    },
    selectedTransformArguments: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    setTransformArgsTo: '',
    descriptionLimit: 60,
    entries: [
      {
        Name: '#AddPrimaryImage',
        Params: 'None',
        Value: '',
        Description: 'No input',
      },
      {
        Name: '#AddImages',
        Params: 'delimiter',
        Value: '',
        Description: 'Field Delimiter',
      },
      {
        Name: '#AddImage',
        Params: 'None',
        Value: '',
        Description: 'No input',
      },
      {
        Name: '#AddDocument',
        Params: 'documentName, displayOrder, extension',
        Value: '',
        Description: 'Document Name, Display Order, File Extension',
      },
      {
        Name: '#SkipField',
        Params: 'None',
        Value: '',
        Description: 'No input',
      },
    ],
    isLoading: false,
    selectedTransformation: null,
    search: null,
  }),
  mounted() {
    if (this.selectedTransformationField) {
      this.selectedTransformation = this.items.find((item) => this.selectedTransformationField == item);
    }
    this.setTransformArgsTo = this.selectedTransformArguments;
  },
  methods: {
    handleTransformArgsInput(value) {
      this.$emit('onTransformArgumentsChange', value);
    },
    handleInput(value) {
      this.$emit('onSelectTransformation', value);
    },
  },

  computed: {
    paramsDescription() {
      if (!this.selectedTransformation) return '';

      return this.entries.find((entry) => {
        return entry.Name === this.selectedTransformation;
      }).Description;
    },
    selectedField() {
      if (!this.selectedTransformation) return {};

      return (
        this.entries.find((entry) => {
          return entry.Name === this.selectedTransformation;
        }) || {}
      );
    },
    items() {
      return this.entries.map((entry) => entry.Name);
    },
  },

  watch: {
    selectedTransformation(val) {
      if (!val) this.$emit('onSelectTransformation', null);
    },
  },
};
</script>